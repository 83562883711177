import API from "./API";

export default class Diaries extends API
{
    static async find(params) {
        return this.get("api/admin/diaries/", {params: params});
    }

    static async aggregator(params) {
        return this.get("api/admin/diaries/aggregator", {params: params});
    }

    static async preview(id, params) {
        return this.get("api/admin/diaries/preview/" + id, {params: params});
    }

    static async deleteDiary(params, id) {
        return this.delete("api/admin/diaries/" + id, {params: params});
    }

    static async approveAllLines(params){
        return this.post('api/admin/diaries/approve-all-lines', params)
    }

    static async getPrecoEquipamento(params){
        return this.get('api/admin/diaries/equipamentos/get-custo',{params: params})
    }

    static async getPrecoMaoObra(params){
        return this.get('api/admin/diaries/equipamentos/get-custo-mo',{params: params})
    }

    static async search(filter) {
        return this.get("api/admin/diaries/search",{params: filter});
    }

}