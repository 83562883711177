var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.alert.showAlert)?_c('MiniAlerts',{staticStyle:{"white-space":"pre-line"},attrs:{"message":_vm.alert.message}}):_vm._e(),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[(_vm.method == 'create')?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"d-none d-lg-block",attrs:{"label":"Insira Data","outlined":"","dense":"","readonly":""},model:{value:(_vm.fields.data),callback:function ($$v) {_vm.$set(_vm.fields, "data", $$v)},expression:"fields.data"}},'v-text-field',attrs,false),on)),_c('v-text-field',{staticClass:"d-block d-lg-none",attrs:{"type":"date","id":"birthday","label":"Insira data","dense":"","outlined":"","max":new Date(new Date()+1).toISOString().substr(0, 10)},model:{value:(_vm.fields.data),callback:function ($$v) {_vm.$set(_vm.fields, "data", $$v)},expression:"fields.data"}})]}}],null,true),model:{value:(_vm.menu0),callback:function ($$v) {_vm.menu0=$$v},expression:"menu0"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu0 = false},"change":_vm.dataChanged},model:{value:(_vm.fields.data),callback:function ($$v) {_vm.$set(_vm.fields, "data", $$v)},expression:"fields.data"}})],1):_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":"","label":"Data"},model:{value:(_vm.fields.data),callback:function ($$v) {_vm.$set(_vm.fields, "data", $$v)},expression:"fields.data"}})],1),(_vm.fields.data != null)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Obras","name":"Obras","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.method == 'update',"items":_vm.obras,"outlined":"","dense":"","item-text":function (item) { return item.Codigo + ' - ' + item.Descricao; },"item-value":"Id","error-messages":errors,"label":"Obras *"},on:{"change":_vm.changeWork},model:{value:(_vm.fields.obra_id),callback:function ($$v) {_vm.$set(_vm.fields, "obra_id", $$v)},expression:"fields.obra_id"}})]}}],null,true)})],1):_vm._e(),(_vm.fields.obra_id != null && _vm.frentes_obra.length > 0 && _vm.hideFrentes == false)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Frentes","name":"Frentes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.method == 'update',"items":_vm.frentes_obra,"outlined":"","dense":"","item-text":function (item) { return item.Descricao; },"item-value":"ID","error-messages":errors,"label":"Frentes de Obra *"},on:{"change":_vm.changeFrenteObra},model:{value:(_vm.fields.frente_id),callback:function ($$v) {_vm.$set(_vm.fields, "frente_id", $$v)},expression:"fields.frente_id"}})]}}],null,true)})],1):_vm._e()],1),(_vm.showContent)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"d-none d-lg-block",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"primary"},[_c('tr',[_c('th',{staticClass:"text-center white--text text-caption font-weight-bold",attrs:{"width":"35%"}},[_vm._v(" Nome UO ")]),_c('th',{staticClass:"text-center white--text text-caption font-weight-bold",attrs:{"width":"15%"}},[_vm._v(" Produção ")]),_c('th',{staticClass:"text-center white--text text-caption font-weight-bold"},[_vm._v(" Notas ")]),_c('th',{staticClass:"text-center white--text text-caption font-weight-bold"},[_vm._v(" Opções ")])])]),_c('tbody',_vm._l((_vm.getLines),function(item,index){return _c('tr',{key:index},[_c('td',[_c('validation-provider',{attrs:{"vid":"Classe","name":"Classe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.mqt!=null)?_c('v-autocomplete',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","items":_vm.classes,"error-messages":errors,"clearable":"","item-text":function (item) { return item.Descricao; },"item-value":"ID","label":"Classe *"},model:{value:(item.classe_id),callback:function ($$v) {_vm.$set(item, "classe_id", $$v)},expression:"item.classe_id"}}):_vm._e()]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"vid":"Produção","name":"Produção","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Produção *","dense":"","outlined":"","error-messages":errors,"clearable":"","type":"number"},model:{value:(item.producao),callback:function ($$v) {_vm.$set(item, "producao", $$v)},expression:"item.producao"}})]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"vid":"Local","name":"Local","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Local","placeholder":"Insira local"},on:{"click":function($event){return _vm.openEditorModal(item)}},model:{value:(item.notas),callback:function ($$v) {_vm.$set(item, "notas", $$v)},expression:"item.notas"}})]}}],null,true)})],1),_c('td',[_c('v-btn',{attrs:{"dark":"","x-small":"","fab":"","color":"error"},on:{"click":function($event){return _vm.deleteLine(item.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-delete")])],1)],1)])}),0)]},proxy:true}],null,true)}),_c('v-data-iterator',{staticClass:"d-block d-lg-none ma-0 pa-0",attrs:{"items":_vm.getLines,"item-key":"id","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-row',{staticClass:"ma-0 pa-0"},_vm._l((items),function(item,index){return _c('v-col',{key:index+200000,staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-2 rounded-lg ma-0 pa-0",attrs:{"elevation":"0","outlined":""}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"align-center"},[_c('validation-provider',{attrs:{"vid":"Unidade de Obra","name":"Unidade de Obra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.mqt!=null)?_c('v-autocomplete',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","items":_vm.classes,"error-messages":errors,"clearable":"","item-text":function (item) { return item.Descricao; },"item-value":"ID","label":"Classe *"},model:{value:(item.classe_id),callback:function ($$v) {_vm.$set(item, "classe_id", $$v)},expression:"item.classe_id"}}):_vm._e()]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"align-center"},[_c('validation-provider',{attrs:{"vid":"Produção","name":"Produção","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Produção *","dense":"","outlined":"","error-messages":errors,"clearable":"","type":"number"},model:{value:(item.producao),callback:function ($$v) {_vm.$set(item, "producao", $$v)},expression:"item.producao"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"align-center"},[_c('validation-provider',{attrs:{"vid":"Local","name":"Local","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","label":"Local","placeholder":"Insira local"},on:{"click":function($event){return _vm.openEditorModal(item)}},model:{value:(item.notas),callback:function ($$v) {_vm.$set(item, "notas", $$v)},expression:"item.notas"}})]}}],null,true)})],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"align-center"},[_c('v-btn',{attrs:{"dark":"","x-small":"","fab":"","color":"error"},on:{"click":function($event){return _vm.deleteLine(item.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)],1)}),1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{staticClass:"primary",attrs:{"fab":""},on:{"click":_vm.addLineToTable}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1):_vm._e(),(_vm.showContent)?_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","align":"end"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":invalid,"type":"submit","rounded":""}},[_vm._v(" Gravar"),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-content-save")])],1)],1)],1):_vm._e()],1)]}}])}),(_vm.editorModal)?_c('v-dialog',{attrs:{"max-width":"600px","scrollable":""},model:{value:(_vm.editorModal),callback:function ($$v) {_vm.editorModal=$$v},expression:"editorModal"}},[_c('InputEditorModal',{attrs:{"vid":"editor_local","title":'Local',"label":'Local'},on:{"change-value":_vm.changeEditorModal},model:{value:(_vm.inputEditorModalData.itemModel),callback:function ($$v) {_vm.$set(_vm.inputEditorModalData, "itemModel", $$v)},expression:"inputEditorModalData.itemModel"}})],1):_vm._e(),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Diária gravada com sucesso ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }